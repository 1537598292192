import React, { useEffect, useState } from "react";
import axios from "axios";
import {
  Box,
  Table,
  Flex,
  Thead,
  Tbody,
  Stack,
  Tr,
  Th,
  Image,
  Td,
  Text,
  Input,
  IconButton,
  Modal,
  ModalOverlay,
  ModalContent,
  useToast,
  ModalHeader,
  ModalBody,
  ModalFooter,
  Button,
  useDisclosure,
} from "@chakra-ui/react";
import { MdSearch } from "react-icons/md";
import { useNavigate } from "react-router-dom";
import logo from "../assets/logo.png";

const Clients = () => {
  const [clients, setClients] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(0);
  const [selectedClient, setSelectedClient] = useState(null); // State for selected client
  const { isOpen, onOpen, onClose } = useDisclosure(); // Modal control
  const navigate = useNavigate();
  const toast = useToast();

  useEffect(() => {
    fetchClients(currentPage);
  }, [currentPage]);

  const fetchClients = async (page) => {
    try {
      const token = localStorage.getItem('token'); // Récupère le token depuis le localStorage
  
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/clients?page=${page}`,
        {
          headers: {
            Authorization: `Bearer ${token}`, // Ajoute le token dans les headers
          },
        }
      );
  
      setClients(response.data.clients); // Utilise la structure de réponse
      setTotalPages(response.data.totalPages); // Utilise la structure de réponse
    } catch (error) {
      console.error("Erreur lors de la récupération des clients:", error);
    }
  };

  const handleSearch = async () => {
    if (!searchTerm.trim()) {
      toast({
        title: "Recherche vide",
        description: "Veuillez entrer un nom pour effectuer une recherche.",
        status: "warning",
        duration: 3000,
        isClosable: true,
      });
      return;
    }

    try {
      const token = localStorage.getItem("token"); // Retrieve token from localStorage

      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/clients/nom`,
        {
          params: { nom: searchTerm },
          headers: {
            Authorization: `Bearer ${token}`, // Add token in headers
          },
        }
      );

      setClients(response.data.clients); // Update clients list with search results
    } catch (error) {
      console.error("Erreur lors de la recherche des clients:", error);
      toast({
        title: "Erreur de recherche",
        description: "Une erreur s'est produite lors de la recherche.",
        status: "error",
        duration: 3000,
        isClosable: true,
      });
    }
  };
  

  const handleClientClick = (client) => {
    setSelectedClient(client); // Set selected client
    onOpen(); // Open modal
  };

  // Function for handling previous page click
  const handlePrevPage = () => {
    setCurrentPage((prev) => (prev > 1 ? prev - 1 : prev));
  };

  // Function for handling next page click
  const handleNextPage = () => {
    setCurrentPage((prev) => (prev < totalPages ? prev + 1 : prev));
  };

  const deleteClient = (clientName) => {
    axios
      .delete(
        `${process.env.REACT_APP_SERVER_URL}/clients?name=${encodeURIComponent(
          clientName.nom
        )}`
      )
      .then((response) => {
        console.log("Client supprimé avec succès:", response.data);
        // Ajoute ici un rechargement des clients ou une mise à jour de l'état
        console.log(clientName.nom, "nom client");
        toast({
          title: "Succès.",
          description: "L'etude a ete supprime avec succes.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        window.location.reload();
      })
      .catch((error) => {
        console.error("Erreur lors de la suppression du client", error);
        console.log(clientName.nom, "nom client");
      });
  };

  return (
    <Box width="full" p={5}>
      <Flex justifyContent="space-between" alignItems="center" mb={4}>
        <Image
          src={logo}
          w={"150px"}
          cursor="pointer"
          onClick={() => navigate("/")}
          alt="Home Solution Logo"
        />
        <Text fontSize="2xl" ml={4}>
          Liste des Clients
        </Text>
        <Flex>
          <Input
            placeholder="Rechercher par nom"
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
          />
          <IconButton
            aria-label="Rechercher"
            icon={<MdSearch />}
            onClick={() => handleSearch()}
          />
        </Flex>
      </Flex>
      <Table variant="simple">
        <Thead bg="gray.200">
          <Tr>
            {/* <Th>ID</Th> */}
            <Th>Nom Du Client</Th>
            <Th>Adresse</Th>
            <Th>Date</Th>
            <Th>Commercial</Th>
          </Tr>
        </Thead>
        <Tbody>
          {clients &&
            clients.map((client, index) => (
              <Tr key={client._id} onClick={() => handleClientClick(client)}>
                {/* <Td>{index + 1}</Td> */}
                <Td>{`${client.nom} ${client.prenom}`}</Td>
                <Td>{client.adresse}</Td>
                <Td>
                  {new Date(client.dateSaved).toLocaleDateString("fr-FR")}
                </Td>
                <Td>{client.commercial}</Td>
              </Tr>
            ))}
        </Tbody>
      </Table>
      <Flex justifyContent="center" mt={4}>
        <Stack direction="row" spacing={4}>
          <Button onClick={handlePrevPage} disabled={currentPage === 1}>
            Précédent
          </Button>
          <Button
            onClick={handleNextPage}
            disabled={currentPage === totalPages}
          >
            Suivant
          </Button>
        </Stack>
      </Flex>

      {/* Modal Component */}
      <Modal isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Informations du client</ModalHeader>
          <ModalBody>
            {selectedClient && (
              <Box>
                <Text>
                  <strong>Commercial:</strong> {selectedClient.commercial}
                </Text>
                <Text>
                  <strong>Nom:</strong> {selectedClient.nom}
                </Text>
                <Text>
                  <strong>Prénom:</strong> {selectedClient.prenom}
                </Text>
                <Text>
                  <strong>Email:</strong> {selectedClient.email}
                </Text>
                <Text>
                  <strong>Adresse:</strong> {selectedClient.adresse}
                </Text>
                <Text>
                  <strong>Sexe:</strong> {selectedClient.sexe}
                </Text>
                <Text>
                  <strong>Province ou IDF:</strong>{" "}
                  {selectedClient.provinceouIdf}
                </Text>
                <Text>
                  <strong>Ville:</strong> {selectedClient.ville}
                </Text>
                <Text>
                  <strong>Téléphone:</strong> {selectedClient.telephone}
                </Text>
                <Text>
                  <strong>Région:</strong> {selectedClient.region}
                </Text>
                <Text>
                  <strong>Département:</strong> {selectedClient.departement}
                </Text>
                <Text>
                  <strong>Nombre d'occupants:</strong> {selectedClient.numberOfOccupants}
                </Text>
                <Text>
                  <strong>Revenu fiscal:</strong> {selectedClient.revenuFiscal}
                </Text>
                <Text>
                  <strong>Pompe sélectionnée:</strong>{" "}
                  {selectedClient.pompeSelected}
                </Text>
                <Text>
                  <strong>Modèle de pompe:</strong>{" "}
                  {selectedClient.pompeModeleSelected}
                </Text>
                <Text>
                  <strong>Consommation annuelle de la pompe:</strong>{" "}
                  {selectedClient.consoAnnuellePompe}
                </Text>
                <Text>
                  <strong>Dépense future pompe:</strong>{" "}
                  {selectedClient.depenseFuturePompe}
                </Text>
                <Text>
                  <strong>Coût projet pompe:</strong>{" "}
                  {selectedClient.coutProjetPompe}
                </Text>
                <Text>
                  <strong>Ma prime pompe:</strong> {selectedClient.maPrimePompe}
                </Text>
                <Text>
                  <strong>Aides CEE pompe:</strong>{" "}
                  {selectedClient.aidesCeePompe}
                </Text>
                <Text>
                  <strong>Consommation annuelle ballon:</strong>{" "}
                  {selectedClient.consoAnnuelleBallon}
                </Text>
                <Text>
                  <strong>Dépense future ballon:</strong>{" "}
                  {selectedClient.depenseFutureBallon}
                </Text>
                <Text>
                  <strong>Coût projet ballon:</strong>{" "}
                  {selectedClient.coutProjetBallon}
                </Text>
                <Text>
                  <strong>Ma prime ballon:</strong>{" "}
                  {selectedClient.maPrimeBallon}
                </Text>
                <Text>
                  <strong>Aides CEE ballon:</strong>{" "}
                  {selectedClient.aidesCeeBallon}
                </Text>
                <Text>
                  <strong>Panneaux sélectionnés:</strong>{" "}
                  {selectedClient.panneauxSelected}
                </Text>
                <Text>
                  <strong>Consommation annuelle panneaux:</strong>{" "}
                  {selectedClient.consoAnnuellePanneaux}
                </Text>
                <Text>
                  <strong>Production annuelle panneaux:</strong>{" "}
                  {selectedClient.productionAnnuellePanneaux}
                </Text>
                <Text>
                  <strong>Dépense future panneaux:</strong>{" "}
                  {selectedClient.depenseFuturePanneaux}
                </Text>
                <Text>
                  <strong>Coût projet panneaux:</strong>{" "}
                  {selectedClient.coutProjetPanneaux}
                </Text>
                <Text>
                  <strong>Prime auto-conso panneaux:</strong>{" "}
                  {selectedClient.primeAutoConsoPanneaux}
                </Text>
                <Text>
                  <strong>Récupération TVA panneaux:</strong>{" "}
                  {selectedClient.recuperationTvaPanneaux}
                </Text>
                {/* Continuez avec d'autres champs comme nécessaire */}
              </Box>
            )}
          </ModalBody>

          <ModalFooter
            display={"flex"}
            flexDirection={"row"}
            justifyContent={"space-between"}
          >
            <Button
              w="100px"
              borderRadius="8px"
              padding="8px 16px"
              colorScheme="red"
              onClick={() => deleteClient(selectedClient)}
            >
              Supprimer
            </Button>
            <Button
              w="100px"
              bgColor="#30C9FF"
              color="#FCFDFF"
              borderRadius="8px"
              padding="8px 16px"
              onClick={onClose}
            >
              Fermer
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default Clients;
