import React, { useContext } from "react";
import { Box, Button, Heading, Text, Flex, Divider } from "@chakra-ui/react";
import { useToast } from "@chakra-ui/react";
import axios from "axios";
import { HomeContext } from "../../../context/HomeContext";
import IconCard from "../../layouts/IconCard";
import IconCardXS from "../../layouts/IconCardXS";
import CounterXS from "../../../assets/CounterXS";
import Picto1 from "./picto2/Picto1.png";
import Picto2 from "./picto2/Picto2.png";
import Picto3 from "./picto2/Picto3.png";
import Picto4 from "./picto2/Picto4.png";
import Picto5 from "./picto2/Picto5.png";
import Picto6 from "./picto2/Picto6.png";

const StepThree = ({ onNext, onBack }) => {
  const [formData, setFormData] = useContext(HomeContext);
  const toast = useToast();

  const handleSaveInformation = () => {
    const commercialName = localStorage.getItem("name"); // Assurez-vous que c'est le bon nom de clé
    const clientData = {
      ...formData,
      commercial: commercialName,
      nom: formData.nom,
      prenom: formData.prenom,
      email: formData.email,
      adresse: formData.adresse,
      sexe: formData.sexe,
      provinceouIdf: formData.provinceOuIdf,
      ville: formData.ville,
      departement: formData.departement,
      habitants: formData.numberOfOccupants,
      revenuFiscal: formData.revenuFiscal,
      typeDinstallation: formData.installationType,
      sourceDenergie: formData.sourceType,
      pompeSelected: formData.pompeSelected,
      pompeModeleSelected: formData.pompeModeleSelected,
      consoAnnuellePompe: formData.consoAnnuellePompe,
      depenseActuellePompe: formData.depenseChauffage,
      depenseFuturePompe: formData.depenseFuturePompe,
      coutProjetPompe: formData.coutProjetPompe,
      maPrimePompe: formData.maPrimePompe,
      aidesCeePompe: formData.aidesCeePompe,
      consoAnnuelleBallon: formData.consoAnnuelleBallon,
      depenseActuelleBallon: formData.depenseECS,
      depenseFutureBallon: formData.depenseFutureBallon,
      coutProjetBallon: formData.coutProjetBallon,
      maPrimeBallon: formData.maPrimeBallon,
      aidesCeeBallon: formData.aidesCeeBallon,
      panneauxSelected: formData.panneauxSelected,
      consoAnnuellePanneaux: formData.consoAnnuellePanneaux,
      productionAnnuellePanneaux: formData.productionAnnuellePanneaux,
      depenseFuturePanneaux: formData.depenseFuturePanneaux,
      coutProjetPanneaux: formData.coutProjetPanneaux,
      primeAutoConsoPanneaux: formData.primeAutoConsoPanneaux,
      recuperationTvaPanneaux: formData.recuperationTvaPanneaux,
      dateSaved: new Date(),
    };

    axios
      .post(`${process.env.REACT_APP_SERVER_URL}/clients`, clientData)
      .then((response) => {
        // Affichez un toast de succès
        toast({
          title: "Succès.",
          description: "Les informations ont été sauvegardées avec succès.",
          status: "success",
          duration: 5000,
          isClosable: true,
        });
        // postClientToIcoll(clientData);
        onNext(); // Procéder à l'étape suivante ou afficher un message de succès
      })
      .catch((error) => {
        console.error("Erreur lors de la sauvegarde des informations:", error);
        // Affichez un toast d'erreur
        toast({
          title: "Erreur.",
          description:
            "Une erreur s'est produite lors de la sauvegarde des informations.",
          status: "error",
          duration: 5000,
          isClosable: true,
        });
      });
  };

  const handleButtonClick = async () => {
    await postClientToIcoll(formData);
    handleSaveInformation();
  };
  // Fonction pour récupérer l'id_icoll depuis MongoDB

  const getUserIcøllId = async () => {
    try {
      // Récupérer le nom de l'utilisateur depuis le local storage
      const userName = localStorage.getItem("name");

      if (!userName) {
        throw new Error(
          "Le nom de l'utilisateur n'est pas présent dans le local storage"
        );
      }

      // Faire la requête pour récupérer l'id_icoll avec le nom de l'utilisateur test
      const response = await axios.get(
        `${process.env.REACT_APP_SERVER_URL}/user/name`,
        {
          params: { name: userName }, // Envoie le nom de l'utilisateur dans les paramètres
        }
      );

      if (response.data && response.data.id_icoll) {
        return response.data.id_icoll; // Retourne l'id_icoll si trouvé
      } else {
        throw new Error("id_icoll not found for the user");
      }
    } catch (error) {
      console.error("Erreur lors de la récupération de l'id_icoll :", error);
      throw error; // Lève l'erreur si la récupération échoue
    }
  };

  // Fonction principale pour envoyer le client à iColl
  const postClientToIcoll = async (formData, userName) => {
    try {
      // Récupérer l'id_icoll de MongoDB
      const id_icoll = await getUserIcøllId(userName);
  
      // Check and adjust sourceType if it contains "CONDENSATION"
      const adjustedSourceType = formData.sourceType.includes("CONDENSATION") ? "FUEL" : formData.sourceType;
  
      // Extract street and number only from the adresse
      const addressOnly = formData.adresse.split(",")[0].trim();
  
      // Préparer les données du client=
      const clientData = {
        clients: [
          {
            id_type_client: "Particulier",
            sexe: formData.sexe === "Monsieur" ? 1 : 2,
            nom: formData.nom,
            prenom: formData.prenom,
            adresse: addressOnly, // Use filtered addresss
            cp: formData.cp,
            ville: formData.ville,
            tel_1: formData.tel1,
            email: formData.email,
            id_commercial: id_icoll,
            comment: "AUCUN",
            id_situation_pro: "AUTRE",
            id_secteur_profession: "PRIVE",
            id_situation_maritale: "MARIE",
            soumis_impot: "OUI",
            ptz_deja_obtenu: "NON",
            aides_deja_obtenu: "NON",
            primo_accedant: "NON",
          },
        ],
      };
  
      const logementData = {
        nom_logement: "Résidence principale",
        adresse: addressOnly, // Use filtered address
        cp: formData.cp,
        ville: formData.ville,
        statut_occupant: "PROPRIETAIRE_RESIDENCE_PRINCIPALE",
        res_principal: "OUI",
        type_residence: "MAISON",
        copro: "NON",
        mitoyen: "NON",
        section: "AUCUN",
        parcelle: "AUCUN",
        date_achevement_maison: "2018-07-10T14:30:00Z",
        shab: "0",
        id_exposition: formData.orientationToiture,
        largeur: "0",
        profondeur: "0",
        nb_niveau: formData.numberOfLevels,
        superficie: formData.surfaceHabitable,
        superficie_terrain: "0",
        elec_puissance_abonnement: "9",
        elec_compteur_linky: "OUI",
        energie_chauffage_principale: adjustedSourceType,
        energie_chauffe_eau: "OUI",
        equipement_chauffe_eau: "CUMULUS",
        lieu_install: "SUR_LA_MAISON",
        compteur: "DANS_LA_MAISON",
        disjoncteur: "DANS_LA_MAISON",
        elec_lieu: "AERIEN",
        id_type_toit: "TUILE",
        superficie_toit: formData.superficieToiture,
        pans_nbr: "2",
        id_orientation_toit: formData.orientationToiture,
        id_inclinaison: formData.inclinaisonToiture,
        masque_solaire: "NON",
      };
  
      // Envoyer les données au backend
      const response = await axios.post(
        `${process.env.REACT_APP_SERVER_URL}/icoll`,
        {
          clientData: clientData,
          logementData: logementData,
        }
      );
  
      console.log("Client et logement postés avec succès sur Icoll :", response.data);
      toast({
        title: "Succès Icoll",
        description: "Les données du client et du logement ont été transmises à Icoll.",
        status: "success",
        duration: 5000,
        isClosable: true,
      });
    } catch (error) {
      console.error("Erreur lors de l'envoi à Icoll :", error.message);
      if (error.response) {
        console.error("Détails de l'erreur :", error.response.data);
      }
      toast({
        title: "Erreur Icoll",
        description: "Une erreur s'est produite lors de l'envoi des données.",
        status: "error",
        duration: 5000,
        isClosable: true,
      });
    }
  };
  
  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Projet du client
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Cout du projet reparti par catégories
          </Text>
        </Box>
        <Button
          ml={"64%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        borderColor={"#F4F4F4"}
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        justifyContent={"center"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto1}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  color="#8C8C8C"
                  mb={"13px"}
                  textAlign="center" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  COUT DU PROJET
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"}>
                  {formData.coutProjet} €
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              mr={"10px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto2}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  mb={"13px"}
                  textAlign="left" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  TOTAL DES AIDES
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"}>
                  {formData.totalAide} €
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              mr={"10px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto3}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  textAlign="center" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  COUT DU PROJET AVEC AIDES <br /> DEDUITES
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"} mt={"-8px"}>
                  {formData.coutProjetAideDeduite} €
                </Box>
              </Box>
            </Box>
          </Box>
          <Box mt={"22px"} display={"flex"} flexDirection={"row"}>
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto4}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  color="#8C8C8C"
                  mb={"13px"}
                  textAlign="left" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  COUT FACTURE SUR 20 ANS
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"}>
                  {Math.round(formData.coutFacture20ans)} €
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              mr={"10px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto5}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  mt={"-8px"}
                  color="#8C8C8C"
                  textAlign="center" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  COUT FACTURE SUR 20 ANS AVEC <br /> LE PROJET
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"}>
                  {Math.round(formData.coutFacture20ansAvecProjet)} €
                </Box>
              </Box>
            </Box>
            <Divider
              orientation="vertical"
              border={"1px solid"}
              h={"251px"}
              mr={"10px"}
              color={"#EAEAEA"}
            />
            <Box flex="1" mr={"10px"}>
              <Box
                display={"flex"}
                w={"295px"}
                h={"258px"}
                flexDirection={"column"}
                alignItems={"center"}
                gap={"10px"}
                borderRadius={"20px"}
                padding={"10px, 10px, 20px, 10px"}
                borderWidth={"1px"}
              >
                <IconCardXS icon={Picto6}></IconCardXS>
                <Text
                  fontSize="14px"
                  fontWeight="510"
                  // mb={"15px"}
                  mt={"-8px"}
                  color="#8C8C8C"
                  textAlign="center" // Aligns text to the left
                  textTransform="uppercase" // Makes text uppercase
                >
                  GAIN SUR 20 ANS INVESTISSEMENT <br /> DEDUIT
                </Text>
                <Box as="span" fontWeight="bold" fontSize={"30px"}>
                  {Math.round(formData.gainSur20ans)} €
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="260px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={handleButtonClick}
        >
          Sauvgarder les informations
        </Button>
      </Flex>
    </Box>
  );
};

export default StepThree;
