import React, { useContext, useState, useEffect } from "react";
import Chart from "chart.js/auto";
import { ArcElement } from "chart.js";
import {
  Box,
  Button,
  Heading,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Stack,
  HStack,
  useRadioGroup,
  Text,
  Flex,
  Divider,
  Select,
  Grid,
  SimpleGrid,
  Image,
} from "@chakra-ui/react";
import { HomeContext } from "../../../context/HomeContext";
import ChartDataLabels from "chartjs-plugin-datalabels";
import { Pie, Doughnut } from "react-chartjs-2";
import IconCard from "../../layouts/IconCard";
import Counter from "../../../assets/Counter";
import Picto1 from "./picto7/Picto1.png";
import Picto2 from "./picto7/Picto2.png";
import Picto3 from "./picto7/Picto3.png";
import Picto4 from "./picto7/Picto4.png";
import Picto5 from "./picto7/Picto5.png";
import Picto6 from "./picto7/Picto6.png";
import Picto7 from "./picto7/Picto7.png";
import Picto8 from "./picto7/Picto8.png";
// import Picto9 from "./picto7/Picto9.png";
import RadioCard from "../../layouts/RadioCard";

const ConsoCard = ({ title, amount, icon, bgColor }) => {

  return (
    <Box
      bgColor={"F7F9FE"}
      w={["248px", "248px", "208px", "248px"]}
      h={"235px"}
    >
      <Box
        display={"flex"}
        w={["248px", "248px", "208px", "248px"]}
        mr={"20px"}
        h={"235px"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={"10px"}
        borderRadius={"20px"}
        padding={"10px, 10px, 20px, 10px"}
        borderWidth={"1px"}
      >
        <Box
          my={"10px"}
          display="flex"
          alignItems="center"
          justifyContent="center"
          borderRadius="18px"
          w={["200px", "200px", "188px", "228px"]}
          h="123px"
          bgColor="#F7F9FE"
        >
          <Image boxSize="40px" src={icon} alt={`Pictogramme de ${title}`} />
        </Box>
        <Text color="#8C8C8C" fontWeight="semibold" fontSize="sm">
          {title}
        </Text>
        <Text color="gray.700" fontSize="lg" fontWeight="bold" my="2">
          {`${amount}€`}
        </Text>
      </Box>
    </Box>
  );
};

const StepSeven = ({ onNext, onBack }) => {
  const [formData, setFormData, defaultFormData] = useContext(HomeContext);

  const [chartData, setChartData] = useState(null);
  const [isButtonVisible, setIsButtonVisible] = useState(true);
  const [showBaremeResult, setShowBaremeResult] = useState(false);
  console.log(formData)


  useEffect(() => {
    Chart.register(ArcElement, ChartDataLabels);
  }, []);
  const calculateDepenseECS = () => {
    if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.3;
    } else if (formData.sourceType === "ELECTRICITE") {
      return formData.factureElectricite * 0.3;
    }
    return formData.factureElectricite * 0.6;
  };


  function calculateDepenseChauffage() {
    if (formData.sourceType === "ELECTRICITE") {
      return formData.factureElectricite * 0.6;
    } else if (formData.energieType === "Chaudiere") {
      return formData.factureSecondaire * 0.7;
    } else {
      return formData.factureSecondaire;
    }
  }


  formData.depenseChauffage = calculateDepenseChauffage(formData);

  formData.depenseECS = calculateDepenseECS();

    const calculateDepensesElec = () => {
      const totalDepenses = formData.factureElectricite + formData.factureSecondaire - formData.depenseChauffage - formData.depenseECS;
      
      formData.depenseMenager = totalDepenses * 0.9;
      formData.depenseEclairage = totalDepenses * 0.1;
    };
    
    // Appeler cette fonction au moment approprié, par exemple lors de la soumission d'un formulaire ou après la saisie des données pertinentes.
    calculateDepensesElec();
    

  const options = {
    maintainAspectRatio: false,
    plugins: {
      datalabels: {
        color: "black",
        font: {
        weight: 'bold', // Mettre les labels en gras
      },
        anchor: "end",
        align: "start",
        formatter: (value, context) => {
          let sum = 0;
          let dataArr = context.chart.data.datasets[0].data;
          dataArr.map((data) => {
            sum += data;
          });
          let percentage = ((value * 100) / sum).toFixed(0) + "%";
          return percentage;
        },
      },
    },
  };

  const generateChartData = () => {
    if (formData) {
      const newData = {
        datasets: [
          {
            label: "Répartition de votre consommation énergétique",
            data: [
              formData.depenseChauffage, // Utilisez la valeur calculée pour le chauffage
              formData.depenseECS, // Utilisez la valeur calculée pour l'eau chaude sanitaire
              formData.depenseMenager, // Utilisez la valeur calculée pour les appareils électriques
              formData.depenseEclairage, // Remplacez par la valeur calculée pour l'éclairage quand elle sera disponible
            ],
            backgroundColor: ["#FD5B00", "#4AB0FE", "#BF09FF", "#FDD500"],
            hoverOffset: 4,
          },
        ],
      };

      setChartData(newData);
      setIsButtonVisible(false);
      setShowBaremeResult(true);
    }
  };

  const consommationAnnuelle =
    formData.depenseChauffage +
    formData.depenseECS +
    formData.depenseMenager +
    formData.depenseEclairage;

  const icons = {
    chauffage: { Picto1 },
    eauChaude: { Picto2 },
    appareils: { Picto3 },
    eclairage: { Picto4 },
  };
  
  formData.consoAnnuelle = consommationAnnuelle
  
  const baremeAideTable = {
    1: { bleu: 23768, jaune: 28933, violet: 40404, rose: 40405 },
    2: { bleu: 34884, jaune: 42463, violet: 59394, rose: 59395 },
    3: { bleu: 41893, jaune: 51000, violet: 71060, rose: 71061 },
    4: { bleu: 48914, jaune: 59549, violet: 83637, rose: 83638 },
    5: { bleu: 55961, jaune: 68123, violet: 95758, rose: 95759 },
    6: { bleu: 62999, jaune: 76691, violet: 107880, rose: 107881 },
    7: { bleu: 70037, jaune: 85259, violet: 120002, rose: 120003 },
    8: { bleu: 77075, jaune: 93827, violet: 132124, rose: 132125 },
    9: { bleu: 84113, jaune: 102395, violet: 144246, rose: 144247 },
    10: { bleu: 91151, jaune: 110963, violet: 156368, rose: 156369 },
  };

  const baremeAideTableProvince = {
    1: { bleu: 17173, jaune: 22015, violet: 30844, rose: 30845 },
    2: { bleu: 25115, jaune: 32197, violet: 45340, rose: 45341 },
    3: { bleu: 30206, jaune: 38719, violet: 54592, rose: 54593 },
    4: { bleu: 35285, jaune: 45234, violet: 63844, rose: 63845 },
    5: { bleu: 40388, jaune: 51775, violet: 73098, rose: 73099 },
    6: { bleu: 45482, jaune: 58300, violet: 82352, rose: 82353 },
    7: { bleu: 50576, jaune: 64825, violet: 91606, rose: 91607 },
    8: { bleu: 55670, jaune: 71350, violet: 100860, rose: 100861 },
    9: { bleu: 60764, jaune: 77875, violet: 110114, rose: 110115 },
    10: { bleu: 65858, jaune: 84400, violet: 119368, rose: 119369 },
  };

  const revenuFiscal = formData.revenuFiscal;
  const numberOfOccupants = formData.numberOfOccupants;
  const factureElectricite = formData.factureElectricite;
  const typeChauffage = formData.sourceType;
  const productionECS = formData.energieType;
  const factureSecondaire = formData.factureSecondaire;
  const departement = formData.departement;
  const altitude = formData.altitude;

  formData.consommationElectrique = factureElectricite / 0.17;

  const getAideColor = () => {
    console.log(formData.consoAnnuelle);
    const baremeAideTableToUse =
      formData.provinceOuIdf === "Ile de France"
        ? baremeAideTable
        : baremeAideTableProvince;

    const aides = baremeAideTableToUse[numberOfOccupants] || {};

    if (revenuFiscal < aides.bleu) {
      return "bleu";
    } else if (revenuFiscal < aides.jaune) {
      return "jaune";
    } else if (revenuFiscal < aides.violet) {
      return "violet";
    } else {
      return "rose";
    }
  };

  formData.aideColor = getAideColor(numberOfOccupants, revenuFiscal);
  const colorScheme = {
    bleu: "bleu",
    jaune: "jaune",
    violet: "violet",
    rose: "rose",
  };

  // const calculateDepenseEclairage = () => {
  //   return formData.factureElectricite * 0.1;
  // };

  // // Exemple d'utilisation
  // formData.depenseEclairage = calculateDepenseEclairage(
  //   formData.factureElectricite
  // );

  useEffect(() => {
    if (formData) {
      generateChartData(); // Assurez-vous que cette fonction ne s'exécute que lorsque formData est défini
    }
  }, [formData]); // Dépendance à formData pour ré-exécuter lorsque formData change

  return (
    <Box width="full" px={20}>
      <Box display={"flex"} flexDirection={"row"}>
        <Box display={"flex"} flexDirection={"column"}>
          <Heading fontSize={"28px"} mb={1} fontWeight={"510"}>
            Consommations poste par poste
          </Heading>
          <Text mb={8} color="#8C8C8C" fontWeight={"400"} fontSize="20px">
            Répartition de la consommation énergétique
          </Text>
        </Box>
        <Button
          ml={"58%"}
          w="120px"
          bgColor={"white"}
          color={"#30C9FF"}
          fontWeight={"600"}
          borderRadius={"8px"}
          padding={"8px, 16px, 8px, 21px"}
          onClick={onBack}
        >
          Back
        </Button>
      </Box>
      <Flex
        direction={{ base: "column", md: "row" }}
        border="1.8px solid"
        p={{ base: "30px", md: "30px 86px" }}
        gap="71px"
        borderRadius="8px"
        bg="#FCFDFF"
        align="center"
        borderColor={"#F4F4F4"}
        bgColor={"white"}
      >
        <Box display={"flex"} flexDirection={"column"}>
          <Box display={"flex"} flexDirection={"row"}>
            <Box display={"flex"} mr={"23.5%"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Vos consommations
              </Heading>
              <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C" mb={8}>
                Résultat de vos consomations poste par poste
              </Text>
            </Box>{" "}
            <Box display={"flex"} flexDirection={"column"}>
              <Heading
                fontSize={"20px"}
                fontWeight={"510"}
                color="gray.700"
                mb={1}
              >
                Résultat Graphique
              </Heading>

              {/* <Text fontSize="17px" fontWeight={"400"} color="#8C8C8C">
                Résultat de consommation poste par poste
              </Text> */}
            </Box>
          </Box>
          <Box display="flex" flexDirection="row" alignItems="center">
            <Box flex="1" mr={["55px", "55px", "85px", "55px"]}>
              <SimpleGrid
                mb="30px"
                columns={2}
                spacingY={["60px", "60px", "30px", "60px"]}
                spacingX={["60px", "60px", "100px", "60px"]}
              >
                <ConsoCard
                  title="CHAUFFAGE"
                  amount={
                    formData.depenseChauffage
                      ? formData.depenseChauffage.toFixed(0)
                      : "N/A"
                  }
                  icon={Picto1}
                  bgColor="#a5bbd4"
                />
                <ConsoCard
                  title="EAU CHAUDE SANITAIRE"
                  amount={
                    formData.depenseECS ? formData.depenseECS.toFixed(0) : "N/A"
                  }
                  icon={Picto2}
                  bgColor="#15af97"
                />
                <ConsoCard
                  title="APPAREILS ÉLECTRIQUES"
                  amount={
                    formData.depenseMenager
                      ? formData.depenseMenager.toFixed(0)
                      : "N/A"
                  }
                  icon={Picto3}
                  bgColor="#0b816e"
                />
                <ConsoCard
                  title="ÉCLAIRAGE"
                  amount={
                    formData.depenseEclairage
                      ? formData.depenseEclairage.toFixed(0)
                      : "N/A"
                  }
                  icon={Picto4}
                  bgColor="#0e8a9f"
                />
              </SimpleGrid>
            </Box>
            <Divider
              orientation="vertical"
              border="1px solid"
              h="480px"
              color="#EAEAEA"
              mx={["45px", "45px", "35px", "45px"]}
            />
            <Box
              mb={"30px"}
              flex="1"
              display="flex"
              w={["400px", "400px", "200px", "400px"]}
              h="516px"
              flexDirection="column"
              alignItems="center" // Assure le centrage horizontal
              gap="10px"
              borderRadius="20px"
              padding="10px 10px 20px 10px"
              borderWidth="1px"
            >
              <Box
                boxSize={["450px", "450px", "320px", "450px"]}
                borderRadius="20px"
                p="4"
                rounded="md"
                bg="#F7F9FE"
              >
                {chartData && <Doughnut data={chartData} options={options} />}
              </Box>
              <Box>
                <Box
                  display={"flex"}
                  fontSize="15px"
                  fontWeight={"semibold"}
                  color={"#737373"}
                  flexDirection={"row"}
                >
                  <Text mr={["76%", "76%", "56%", "76%"]}>Label</Text>
                  <Text>Account</Text>
                </Box>
                <Divider w={["402px", "402px", "302px", "402px"]} my={"5px"} />
                <Box display="flex" alignItems="center" mt={2}>
                  <Image src={Picto5} boxSize="8px" mr={2} />
                  <Text
                    color="#737373"
                    fontSize={"15px"}
                    fontWeight={450}
                    flex="1"
                  >
                    CHAUFFAGE
                  </Text>
                  <Text fontWeight="440">
                    {formData.depenseChauffage?.toFixed(0)}€
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                  <Image src={Picto6} boxSize="8px" mr={2} />
                  <Text
                    color="#737373"
                    fontSize={"15px"}
                    fontWeight={450}
                    flex="1"
                  >
                    EAU CHAUDE SANITAIRE
                  </Text>
                  <Text fontWeight="440">
                    {formData.depenseECS?.toFixed(0)}€
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                  <Image src={Picto7} boxSize="8px" mr={2} />
                  <Text
                    color="#737373"
                    fontSize={"15px"}
                    fontWeight={450}
                    flex="1"
                  >
                    APPAREILS ÉLECTRIQUES
                  </Text>
                  <Text fontWeight="440">
                    {formData.depenseMenager?.toFixed(0)}€
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                  <Image src={Picto8} boxSize="8px" mr={2} />
                  <Text
                    color="#737373"
                    fontSize={"15px"}
                    fontWeight={450}
                    flex="1"
                  >
                    ÉCLAIRAGE
                  </Text>
                  <Text fontWeight="440">
                    {formData.depenseEclairage?.toFixed(0)}€
                  </Text>
                </Box>
                <Divider w={["402px", "402px", "302px", "402px"]} my={"5px"} />
                <Box display="flex" alignItems="center" mt={2}>
                  <Text
                    color="#737373"
                    fontSize={"17px"}
                    fontWeight={500}
                    flex="1"
                  >
                    Consommation Annuelle
                  </Text>
                  <Text fontWeight="440">
                    {consommationAnnuelle.toFixed(0)}€
                  </Text>
                </Box>
                <Box display="flex" alignItems="center" mt={2}>
                  <Text
                    color="#737373"
                    fontSize={"17px"}
                    fontWeight={500}
                    flex="1"
                  >
                    Bareme Aide
                  </Text>
                  <Text fontWeight="440">{formData.aideColor}</Text>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      </Flex>
      <Flex justifyContent="flex-end" my="3%">
        <Button
          w="238px"
          bgColor="#30C9FF"
          color="#FCFDFF"
          borderRadius="8px"
          padding="8px 16px"
          onClick={onNext}
        >
          Confirmez les informations
        </Button>
      </Flex>
    </Box>
  );
};

export default StepSeven;
